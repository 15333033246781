import React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"

const StyledInvalidTokenPage = styled.div`
  text-align: center;
  p {
    max-width: 400px;
    margin: auto;
  }
`

const InvalidTokenPage = () => {
  return (
    <Layout>
      <StyledInvalidTokenPage>
        <h2>Ogiltig länk.</h2>
        <p>
          Länken du klickat på är ogiltig. Förmodligen beror detta på att
          länkens giltighetstid har gått ut.
          <br />
          <a
            href="https://h2oteknik.se/kontakt.html"
            target="_blank"
            rel="noreferrer"
          >
            Kontakta oss{" "}
          </a>
          för att få en ny länk.
        </p>
      </StyledInvalidTokenPage>
    </Layout>
  )
}

export default InvalidTokenPage
